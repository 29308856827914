<template>
  <b-modal :active.sync="isModalActive" has-modal-card>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Informacija</p>
      </header>
      <section class="modal-card-body">
        <slot/>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="cancel">Atšaukti</button>
        <button :class="confirmButtonClass" @click="confirm">{{ confirmLabel }}</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalBox2',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    confirmLabel: {
      type: String,
      default: 'Patvirtinti'
    },
    confirmType: {
      type: String,
      default: 'is-info'
    }
  },
  data () {
    return {
      isModalActive: false
    }
  },
  computed: {
    confirmButtonClass () {
      return `button ${this.confirmType}`
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    confirm () {
      this.$emit('confirm')
    }
  },
  watch: {
    isActive (newValue) {
      this.isModalActive = newValue
    },
    isModalActive (newValue) {
      if (!newValue) {
        this.cancel()
      }
    }
  }
}
</script>
