<template>
  <div>
  <modal-view-list :is-active="isModalView" :view-subject="viewObjectName" :view-info="viewInfoName" @confirm="viewConfirm" @cancel="viewCancel"/>
    <section class="section is-main-section">
      <card-component title="VALDYMAS" icon="account-multiple">
      </card-component>
      <card-component title="Administracija" icon="account-multiple">
      <b-table
        bordered
        :narrowed="true"
		:opened-detailed="defaultOpenedDetails"
        detailed
        detail-key="name"
        @details-open="(row, index) => set_dir(row, index)"
        :data="folder">
            <b-table-column field="name" label="Parduotuve" v-slot="props">
                  {{ props.row.name }}
            </b-table-column>
			<template #detail="props">
			  <b-table
				bordered
				:narrowed="true"
				:data="props.row.files">
					<b-table-column label="Pavadinimas" v-slot="props">
						  {{ props.row }}
					</b-table-column>
					<b-table-column label="DATA" v-slot="props">
						  {{ new Date((props.row.split(".")[0])*1000).toLocaleString() }}
					</b-table-column>
					<b-table-column label="..." v-slot="props">
						<div class="buttons">
							<b-button type="is-info is-small" @click.prevent="getFile(ip, props.row)" icon-right="edit"/>
						</div>
					</b-table-column>
				  </b-table>
            </template>
          </b-table>
      </card-component>
    </section>
  </div>

</template>

<style  scoped>

</style>

<script>
import CardComponent from '@/components/CardComponent'
import CardToolbar from '@/components/CardToolbar'
import ModalViewList from '@/components/ModalViewList'

export default {
  name: "Admin",
  components: {CardToolbar, CardComponent, ModalViewList },
  data () {
    return {
	defaultOpenedDetails: [1],
    showDetailIcon: true,
	isModalView: false,
    viewObject: null,
    viewInfo: null,
	ip: '',
	folder: [],
	files: [],
    }
  },
  computed: {
  },
  created () {
    this.getDir();
  },
  mounted() {
  },

  methods: {
	viewObjectName () {
      if (this.viewObject) {
        return this.viewObject
      }
      return null
    },  
    viewInfoName () {
      if (this.viewInfo) {
        return this.viewInfo
      }
      return null
    },
	set_dir(row, index){
	this.ip = row.name;
	},
	getDir () {
      this.axios
      .get('php/dir_all.php')
      .then(response => {
        this.folder = response.data;       
      })
      .catch( err => {
		console.log(err.message);
      })
    },
	getFile (dir, file) {
	let list = [];
	this.axios
    .post(`/php/file_content.php`, {
		dir: dir,
        file: file,
        })
    .then(response => {
	  list = response.data;
	  this.viewModal(dir, list);
      console.log(list)
	})
    .catch( err => {
	  console.log(err.message)
    })
    },
	
	viewModal (viewObject, viewInfo) {
      this.viewObject = viewObject
      this.viewInfo = viewInfo
      this.isModalView = true
    },
    viewConfirm () {
      this.isModalView = false
    },
    viewCancel () {
      this.isModalView = false
    },
  }
}
</script>
