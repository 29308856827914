/* Styles */
import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

/* Router & Store */
import router from './router'
import store from './store'

import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);

//import { StreamBarcodeReader } from "vue-barcode-reader";

import VueQuagga from 'vue-quaggajs';
// register component 'v-quagga'
Vue.use(VueQuagga);

/* Vue. Main component */
import App from './App.vue'

/* Fetch sample data */
//store.dispatch('fetch', 'clients')

/* Default title tag */
const defaultDocumentTitle = 'Sidonas ir KO'

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

Vue.config.productionTip = false

Vue.use(Buefy)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
