import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
//import Camera from '@/views/Camera.vue'
import Admin from '@/views/Admin.vue'

Vue.use(VueRouter)

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Pagrindinis'
    },
    path: '/',
    name: 'home',
    component: Home
  },
  {
  path: '/admin',
  name: 'admin',
  component: Admin
  },
  /*{
  path: '/camera',
  name: 'camera',
  component: Camera
  },*/
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router

export function useRouter () {
  return router
}
