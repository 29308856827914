<template>
  <modal-box2 :is-active.sync="isActive" @confirm="confirm" @cancel="cancel" confirm-type="is-success" confirm-label="Uždaryti">
    <div>Parduotuve: {{ viewSubject() }}</div>
      <hr>
	  <b-table
        bordered
        :narrowed="true"
        :data="viewInfo()">
			<b-table-column field="kodas" label="Kodas" v-slot="props">
				  {{ props.row.kodas }}
			</b-table-column>
			<b-table-column field="kiekis" label="Kiekis" v-slot="props">
				  {{ props.row.kiekis }}
			</b-table-column>
          </b-table>
		  <hr>
		  <vue-excel-xlsx class = "button is-dark is-medium" 
			:data="viewInfo()" 
			:columns="columns" 
			:file-name="'filename'"
			:file-type="'xlsx'"
			:sheet-name="'sheetname'">
            ATSISIŲSTI
        </vue-excel-xlsx>
  </modal-box2>
</template>

<script>
import ModalBox2 from "./ModalBox2";

export default {
  name: "ModalEditList",
  components: {ModalBox2},
  data(){
    return{
	columns : [
        {label: "Barkodas", field: "kodas"},
        {label: "Kiekis", field: "kiekis"},
    ],
    }
  },
  props:{
    isActive: {
      type: Boolean,
      default: false
    },
    viewSubject: {
      type: String,
      default: []
    },
    viewInfo: {
      type: Array,
      default: null
    }
  },
  computed: {
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    confirm () {
      this.$emit('confirm')
    }
  }
}
</script>

