export default [
  'Pagrindinis',
  [
    {
      to: '/',
      icon: 'desktop-mac',
      label: 'Į pradžia'
    }
  ],
  'Meniu',
  [
	/*{
      to: '/camera',
      icon: 'camera',
      label: 'Kamera'
    },*/
	{
      to: '/admin',
      icon: 'account',
      label: 'Admin'
    }
  ]
]
