<template>
  <div>
	<b-modal
		v-model="isComponentModalActive"
		@close="onClose">
		<div class="modal-card" style="width: auto">
			<header class="modal-card-head">
				<p class="modal-card-title">Pasirinkite</p>
			</header>
			<section class="modal-card-body">
				<b-field label="Parduotuvė:" horizontal>
				<b-select  placeholder="Pasirinkite" v-model="store" expanded>
					<option v-for='(idx, id) in lt' :key='idx' :value="idx">{{ idx }}</option>
				</b-select>
			</b-field>
			</section>
			<footer class="modal-card-foot">
				<b-button
					label="Išsaugoti pasirinkimą"
					@click="select_store()" />
			</footer>
		</div>
    </b-modal>
    <section class="section is-main-section">
      <modal-trash-box :is-active="isModalActive" :trash-subject="trashObjectName" @confirm="trashConfirm" @cancel="trashCancel" />
      <card-component title="VALDYMAS" icon="account-multiple">
	  <br>
        <!--<b-button size="is-medium" type="is-dark">IŠSAUGOTI</b-button> -->
        <vue-excel-xlsx class = "button is-dark is-medium" :data="barlist" :columns="columns" :filename="filename" :sheetname="sheetname">
            ATSISIŲSTI
        </vue-excel-xlsx>
      </card-component>
      <card-component title="Barkodai" icon="account-multiple">
       <b-field horizontal label="Paskutinis barkodas:">
            <b-input name="barcode" v-model="old_barcode" expanded></b-input>
            <b-input name="kiekis" v-model="kiekis" @keyup.native.space="manual_add()" expanded></b-input>
             <p class="control">
                <b-button label="OK" @click.prevent="manual_add()" type="is-info" expanded/>
            </p>
        </b-field>
      <hr>
	  <div class="has-text-center">{{ store }}</div>
	  <hr>
      <b-table
		:row-class="onRowClass"
        bordered
        :narrowed="true"
        :data="barlist">
            <b-table-column v-slot="props" label="Nr.">
              {{props.index + 1}}
            </b-table-column>
            <b-table-column label="Barkodas" field="kodas" v-slot="props">
                  {{props.row.kodas}}
            </b-table-column>
            <b-table-column class="has-text-right" label="Kiekis" v-slot="props"  field="kiekis">
                  {{  props.row.kiekis }}
            </b-table-column>
            <b-table-column label="..." v-slot="props">
                   <b-button @click.prevent="trashModal(props.row.kodas, props.index)" type="is-danger" icon-right="delete" />
            </b-table-column>
          <section class="section" slot="empty">
            <div class="content has-text-centered">
              <template>
                <p>
                  <b-icon icon="emoticon-sad" size="is-large"/>
                </p>
                <p>Duomenų nerasta &hellip;</p>
              </template>
            </div>
          </section>
          <template slot="footer">
                <th class="has-text-right">VISO:</th>
                <th> </th>
                <th class="has-text-left">{{ viso }}</th>
            </template>
          </b-table>
      </card-component>
    </section>
  </div>

</template>

<style  scoped>

</style>

<script>
	/*let parse2 = (input) => {
		let match = input.match(/Alt\d\d\d/i);
		return match ? (res.split('Alt').filter(el => el.length).map(el => String.fromCharCode(el)).join('')) : input;
	};*/
import CardComponent from '@/components/CardComponent'
import CardToolbar from '@/components/CardToolbar'
import ModalTrashBox from '@/components/ModalTrashBox'

export default {
  name: "Barcode",
  components: {CardToolbar, CardComponent, ModalTrashBox},
  data () {
    return {
	isComponentModalActive: false,
	store: '',
	newas: {
		laikas: '',
		json: ''
	},
	laik: Math.round((new Date()).getTime() / 1000),
	color: [
        'is-one2', 'is-two2', 'is-three2'
    ],
    filename: 'failas',
    sheetname: 'pirmas lapas',
    lt: [
        "MINS", "TELS", "MADA", "MARI", "NORF", "BIGA", "BABI", "UKME", "MANT", "VISA", 
        "KEDA","AREN", "MAXI", "PANE", "MAZE", "TAIK", "SAUL", "TAUB", "UTEN", "TESTAS"
    ],
	folder: '',
    barcode: '',
    old_barcode: '',
    kiekis: 0,
	barlist: [],
    interval: 0,
    columns : [
        {label: "Barkodas", field: "kodas"},
        {label: "Kiekis", field: "kiekis"},
    ],
    isModalActive: false,
    trashObject: null,
    trashObjectId: null,
    }
  },
  computed: {
    viso: function(){
      let total = [];
      Object.entries(this.barlist).forEach(([key, val]) => {
          total.push(val.kiekis) // the value of the current key.
      });
      return total.reduce(function(total, num){ return total + num }, 0);
    },
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject
      }
      return null
    },
  },
  created () {
	this.isComponentModalActive = true;
    //this.getData();
  },
  mounted() {
  	let parser = input => {
		//alert(input);
		console.log(input);
		return input.replace(/Alt\d\d\d/g,(el)=>{
			return String.fromCharCode(el.replace('Alt','')) 
			}) 
		}
		
    window.addEventListener("keydown", event => {
      if (this.interval)
        clearInterval(this.interval);

      if (event.code == 'Enter') {
	  console.log("ikeliamas barkodas");
        if (this.barcode){
			if(this.store == "SAUL"){
				this.barcode = parser(this.barcode);
			}
			//if(this.store == "MARI"){
				//this.barcode = this.barcode.replace(/Enter/gi,"");
			//}
			this.old_barcode = this.barcode;
			this.kiekis = 1;
			  var index = this.barlist.findIndex(object => {
				return object.kodas === this.barcode;
			  });
			  if(index == -1){
				this.barlist.push({'kodas': this.barcode, 'kiekis': parseInt(this.kiekis)});
				}else{
				this.barlist[index].kiekis += 1;
				}
				console.log(this.barcode);
			this.barcode = '';
			this.suformuoti();
		}else{
		console.log("Barkodas tuscias");
		}
      }

      if (event.key != 'Shift'){
          this.barcode += event.key;
		  console.log(event.key);
		  console.log(this.barcode);
          this.interval = setInterval(() => this.barcode = '', 30);
      }
    });
  },

  methods: {
	select_store(){
		if(this.store){
			this.isComponentModalActive = false;
		}else{
			alert("Pasirinkite parduotuve !!!");
		}
	},
	onRowClass: function (row, index) {
      if(this.old_barcode == row.kodas){
        return this.color[0];
      }
    },
	onClose() {
		alert("Pasirinkite parduotuve !!!");
		location.reload()
    },
	getData () {
      this.axios
      .get('php/read_dir.php')
      .then(response => {
        this.folder = response.data;       
      })
      .catch( err => {
		console.log(err.message);
      })
    },
	suformuoti(){
	if(!this.store){
		this.isComponentModalActive = true; 
	}else{
		this.newas.laikas = this.laik;
		this.newas.store = this.store;
		this.newas.json = JSON.stringify(this.barlist);
			this.axios
			  .post(`php/write.php`, this.newas)
			  .then(response => {
				console.log(response.data)
			})
			  .catch( err => {
			  console.log(err.message);
			})
		}
    },
    manual_add(){
      console.log('RANKINIS');
      if (this.old_barcode && this.kiekis > 0)
          var index = this.barlist.findIndex(object => {
            return object.kodas === this.old_barcode;
          });
          if(index == -1){
            this.barlist.push({'kodas': this.old_barcode, 'kiekis': parseInt(this.kiekis)});
            }else{
            this.barlist[index].kiekis += parseInt(this.kiekis);
            }
          this.kiekis = 1;
		  this.suformuoti();
        return;
    },
    delete_barcode(){
      var newas = [];
      var x = 0;
      for (let i = 0; i < this.barlist.length; i++) {
        if(i !== this.trashObjectId){
          newas[x] = this.barlist[i];
          x++;
          console.log(this.barlist[i]);
        }
      } 
      this.barlist = newas;
      console.log("Istrinta");
    },

    trashModal (trashObject, trashObjectId) {
      this.trashObject = trashObject
      this.trashObjectId = trashObjectId
      this.isModalActive = true
    },
    trashConfirm () {
      this.isModalActive = false
      this.delete_barcode();
    },
    trashCancel () {
      this.isModalActive = false
    },
  }
}
</script>
